import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import Standings from '../components/standings'
import Schedule from '../components/schedule'

const RootIndex = ({ location, data }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allContentfulBlogPost.edges

  return (
    <Layout location={location}>
      <Helmet title={siteTitle} />
      <Hero data={posts[0].node} />
      <br />
      <div className="site-content">
        <div className="container">
          <div className="row">
            <div className="content col-lg-8">
              <div className="row">
                {posts.map(({ node }) => {
                  return <ArticlePreview article={node} key={node.slug} />
                })}
              </div>
            </div>
            <div className="sidebar col-lg-4">
              <Standings />
              <Schedule />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
