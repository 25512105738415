import React from 'react';
import './standings.css';

const Standings = () => {
  return (
    <aside className="card standings">
      <div className="standings-title card-header has-btn">
        <h5>NXL SemiPro Standings</h5>
      </div>
      <div className="standings-content card-content">
        <div className="table-responsive">
          <table className="table table-hover table-standings">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Points</th>
                <th>Points Back</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="team-meta">
                     #1
                    <figure className="team-meta-logo">
                      <img src="https://alchemists.dan-fisher.com/esports/assets/images/samples/logos/pirates_shield.png" alt="Placeholder team logo" />
                    </figure>
                    <div className="team-meta-info">
                      <h6 className="team-meta-name">A.C. Diesel</h6>
                    </div>
                  </div>
                </td>
                <td>
                    359
                </td>
                <td>
                    0
                </td>
              </tr>
              <tr>
                <td>
                  <div className="team-meta">
                     #2
                    <figure className="team-meta-logo">
                      <img src="https://alchemists.dan-fisher.com/esports/assets/images/samples/logos/pirates_shield.png" alt="Placeholder team logo" />
                    </figure>
                    <div className="team-meta-info">
                      <h6 className="team-meta-name">CEPP DMG</h6>
                    </div>
                  </div>
                </td>
                <td>
                    342
                </td>
                <td>
                   -17 
                </td>
              </tr>
              <tr>
                <td>
                  <div className="team-meta">
                     #3
                    <figure className="team-meta-logo">
                      <img src="https://alchemists.dan-fisher.com/esports/assets/images/samples/logos/pirates_shield.png" alt="Placeholder team logo" />
                    </figure>
                    <div className="team-meta-info">
                      <h6 className="team-meta-name">Gulf Coast Hurricanes</h6>
                    </div>
                  </div>
                </td>
                <td>
                    329
                </td>
                <td>
                    -30
                </td>
              </tr>
              <tr>
                <td>
                  <div className="team-meta">
                     #4
                    <figure className="team-meta-logo">
                      <img src="https://alchemists.dan-fisher.com/esports/assets/images/samples/logos/pirates_shield.png" alt="Placeholder team logo" />
                    </figure>
                    <div className="team-meta-info">
                      <h6 className="team-meta-name">Columbus LVL</h6>
                    </div>
                  </div>
                </td>
                <td>
                    316
                </td>
                <td>
                   43
                </td>
              </tr>
              <tr>
                <td>
                  <div className="team-meta">
                     #5
                    <figure className="team-meta-logo">
                      <img src="https://alchemists.dan-fisher.com/esports/assets/images/samples/logos/pirates_shield.png" alt="Placeholder team logo" />
                    </figure>
                    <div className="team-meta-info">
                      <h6 className="team-meta-name">Colorado Blitz</h6>
                    </div>
                  </div>
                </td>
                <td>
                    304
                </td>
                <td>
                    -52
                </td>
              </tr>
              <tr>
                <td>
                  <div className="team-meta">
                     #6
                    <figure className="team-meta-logo">
                      <img src="https://alchemists.dan-fisher.com/esports/assets/images/samples/logos/pirates_shield.png" alt="Placeholder team logo" />
                    </figure>
                    <div className="team-meta-info">
                      <h6 className="team-meta-name">Toulouse TonTons</h6>
                    </div>
                  </div>
                </td>
                <td>
                    289
                </td>
                <td>
                    -70
                </td>
              </tr>
              <tr className="highlighted">
                <td>
                  <div className="team-meta">
                     #7
                    <figure className="team-meta-logo">
                      <img src="https://alchemists.dan-fisher.com/esports/assets/images/samples/logos/pirates_shield.png" alt="Placeholder team logo" />
                    </figure>
                    <div className="team-meta-info">
                      <h6 className="team-meta-name">Hurricanes</h6>
                    </div>
                  </div>
                </td>
                <td>
                    285
                </td>
                <td>
                    -74
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </aside>
  )
}

export default Standings;
