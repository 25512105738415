import React from 'react';
import './schedule.css';

const Schedule = () => {
  return (
    <aside className="card schedule">
      <div className="standings-title card-header has-btn">
        <h5>Upcoming Schedule</h5>
      </div>
      <div className="schedule-content card-content">
        <ul className="canes-event-list list-unstyled">
          <li className="canes-event-list-item">
            <div className="canes-event-list-item-header">
              <time className="canes-event-list-item-date">Sunday, October 13th</time>
            </div>
            <div className="canes-event-list-item-body">
              <div className="canes-event-list-item-time">
                  9:00 AM
              </div>
              <div className="canes-event-list-item-info">
                <h6 className="canes-event-list-item-title">Non-layout Practice</h6>
                <p className="canes-event-list-item-desc">Boston Paintball, Maynard</p>
              </div>
            </div>
          </li>
          <li className="canes-event-list-item">
            <div className="canes-event-list-item-header">
              <time className="canes-event-list-item-date">Wednesday, November 6th</time>
            </div>
            <div className="canes-event-list-item-body">
              <div className="canes-event-list-item-time">
                  2:00 PM
              </div>
              <div className="canes-event-list-item-info">
                <h6 className="canes-event-list-item-title">World Cup Practice</h6>
                <p className="canes-event-list-item-desc">Orlando, FL</p>
              </div>
            </div>
          </li>
          <li className="canes-event-list-item">
            <div className="canes-event-list-item-header">
              <time className="canes-event-list-item-date">Thursday, November 7th</time>
            </div>
            <div className="canes-event-list-item-body">
              <div className="canes-event-list-item-time">
                 8:00 AM
              </div>
              <div className="canes-event-list-item-info">
                <h6 className="canes-event-list-item-title">NXL World Cup</h6>
                <p className="canes-event-list-item-desc">Orlando, FL</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </aside>
  )
}

export default Schedule;
